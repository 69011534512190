import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
  <div className="container">
    <h1 className="title">Bienvenue sur Annecy Météo Web (beta)</h1>
    <p className="subtitle">
      L'application météo android et iOS de référence pour Annecy et ses montagnes.
    </p>
    <p className="subtitle">
      Le site internet est en cours de création.
    </p>
    <Link to="/politique-de-confidentialite" className="button">
      Voir notre politique de confidentialité
    </Link>
  </div>
);

export default Home;