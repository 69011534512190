import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home'; // Assurez-vous que ce fichier existe
import PrivacyPolicy from './PrivacyPolicy'; // Assurez-vous que ce fichier existe
import './App.css'; // Import du fichier CSS

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
    </Routes>
  </Router>
);

export default App;