import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1 className="title">Politique de Confidentialité de Annecy Météo</h1>
      <p className="text"><strong>Date d'entrée en vigueur : 5 décembre 2024</strong></p>
      
      <h2 className="subtitle">1. Introduction</h2>
      <p className="text">
        Nous, chez Annecy Météo, respectons votre vie privée et nous engageons à protéger les
        informations personnelles que vous partagez avec nous. Cette politique de confidentialité
        explique comment nous collectons, utilisons, et partageons vos informations lorsque vous
        utilisez notre application mobile "Annecy Météo".
      </p>
      
      <h2 className="subtitle">2. Données Collectées</h2>
      <p className="text">
        Lors de l'utilisation de notre application, nous pouvons collecter certains types
        d'informations pour fournir et améliorer nos services.
      </p>
      <h3 className="subtitle">2.1 Informations Personnelles</h3>
      <p className="text">
        - Données d'identification : Nous ne collectons pas d'informations personnelles identifiables telles
        que votre nom, votre adresse ou votre adresse e-mail, sauf si vous nous les fournissez volontairement.
      </p>
      <h3 className="subtitle">2.2 Données d'Utilisation</h3>
      <p className="text">
        - Nous pouvons collecter des données d'utilisation de l'application, y compris l'interaction avec les
        fonctionnalités, la fréquence d'utilisation et les erreurs techniques.
      </p>
      <h3 className="subtitle">2.3 Données de Localisation</h3>
      <p className="text">
        - Localisation : Avec votre consentement, nous pouvons collecter et traiter des informations sur
        votre emplacement actuel pour fournir des prévisions météorologiques précises pour votre région.
        Vous pouvez désactiver la collecte de ces données via les paramètres de votre appareil.
      </p>
      
      <h2 className="subtitle">3. Utilisation des Données</h2>
      <p className="text">
        Nous utilisons les données collectées aux fins suivantes :
        <ul>
          <li>Fournir et améliorer notre service de prévisions météorologiques.</li>
          <li>Personnaliser l'expérience utilisateur.</li>
          <li>Diagnostiquer et corriger des problèmes techniques.</li>
        </ul>
      </p>
      
      <h2 className="subtitle">4. Partage des Données</h2>
      <p className="text">
        Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
        <ul>
          <li>
            <strong>Fournisseurs de services :</strong> Nous utilisons Firebase pour des services d'analyse et de notifications.
            Ces fournisseurs de services peuvent avoir accès à des données anonymes pour effectuer des tâches en notre nom.
          </li>
          <li>
            <strong>Exigences légales :</strong> Nous pouvons divulguer vos informations si la loi l'exige ou si nous croyons
            en toute bonne foi qu'une telle action est nécessaire pour se conformer à une obligation légale.
          </li>
        </ul>
      </p>
      
      <h2 className="subtitle">5. Sécurité des Données</h2>
      <p className="text">
        Nous prenons des mesures raisonnables pour protéger vos informations contre tout accès,
        divulgation, modification ou destruction non autorisés. Toutefois, aucune méthode de transmission
        sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir leur sécurité absolue.
      </p>
      
      <h2 className="subtitle">6. Vos Droits</h2>
      <p className="text">
        En fonction de votre localisation, vous pouvez avoir les droits suivants concernant vos informations personnelles :
        <ul>
          <li>Accéder, corriger ou supprimer vos données.</li>
          <li>Retirer votre consentement pour la collecte de données de localisation.</li>
        </ul>
        Pour exercer ces droits, vous pouvez nous contacter à <strong>corentin.brugnon@gmail.com</strong>.
      </p>
      
      <h2 className="subtitle">7. Modifications de cette Politique</h2>
      <p className="text">
        Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Toute modification
        sera publiée sur cette page et vous en serez informé par une notification dans l'application.
      </p>
      
      <h2 className="subtitle">8. Contactez-nous</h2>
      <p className="text">
        Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à :
        <ul>
          <li>Email : corentin.brugnon@gmail.com</li>
          <li>Adresse : 123 avenue de Genève, Annecy</li>
        </ul>
      </p>
      
      <a href="/" className="button">Retour à l'accueil</a>
    </div>
  );
};

export default PrivacyPolicy;